import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { EtobicokeCommunityHistory } from 'components/EtobicokeCommunityHistory'
import { EtobicokeCommunityGeo } from 'components/EtobicokeCommunityGeo'
import { OurPromise } from 'components/OurPromise'
import { GivesBack } from 'components/GivesBack'
import { EtobicokeCommunityHero } from 'components/EtobicokeCommunityHero'
import { EtobicokeCommunityInformation } from 'components/EtobicokeCommunityInformation'
import { CommunitySearch } from 'components/CommunitySearchEtobicoke'
import { GetListingsOptionsType } from '@services/repliers/get-listings.service'
import { GetListingsType } from '@services/repliers/repliers-services.type'
import { services } from '@services'
import PropertiesComponent from 'components/PropertiesComponentEtobicoke'
import { FlexDiv } from 'design-system/FlexDiv'
import { Contact } from 'components/Contact'

const Etobicoke: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <FlexDiv className="cityPage">
      <PropertiesComponent defaultCity="Etobicoke"/>
        {/* <CommunitySearch /> */}
        <Contact />
        </FlexDiv>
    </EllicotLayout>
    
  )
}

export default Etobicoke
