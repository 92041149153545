import React from 'react'
import styled from 'styled-components'

const PagiBtn = styled.button`
  font-family: CentraNo2-Book;
`

type PaginationPropsType = {
  numPages: number
  currentPage: number
  setPage: (page: number) => void
}

export const Pagination: React.FunctionComponent<PaginationPropsType> = ({
  currentPage,
  numPages,
  setPage,
}) => {
  const leftButtonCount = currentPage < 5 ? currentPage - 1 : 4
  const rightButtonCount =
    currentPage > numPages - 5 ? numPages - currentPage : 4
  return (
    <div>
      <PagiBtn
        onClick={() => (currentPage > 1 ? setPage(currentPage - 1) : undefined)}
      >
        Prev
      </PagiBtn>
      {new Array<number>(leftButtonCount)
        .fill(1)
        .map((_, i) => i)
        .reverse()
        .map(i => (
          <PagiBtn
            onClick={() => setPage(currentPage - i - 1)}
            key={`prev-${i}`}
          >
            {currentPage - i - 1}
          </PagiBtn>
        ))}
      <PagiBtn disabled={true}>{currentPage}</PagiBtn>
      {new Array<number>(rightButtonCount).fill(1).map((_, i) => (
        <PagiBtn onClick={() => setPage(i + currentPage + 1)} key={`next-${i}`}>
          {i + currentPage + 1}
        </PagiBtn>
      ))}
      <PagiBtn
        onClick={() =>
          currentPage < numPages ? setPage(currentPage + 1) : undefined
        }
      >
        Next
      </PagiBtn>
    </div>
  )
}
